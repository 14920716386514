
export const environment = {
  production: false,
  apiUrl: 'https://api.dev.digitalreception.ch/v1',
  defaultAppLanguage: 'de',
  hasRemoteLogging: false,
  title: 'Konnex',
  instanceKey: 'konnex',
  templateKey: '.konnex-warehouse',
  instanceReception: {
    key: 'warehouse',
    label: 'Warehouse'
  },
  cardDispenserExtensionId: '',
  logoUrl: '/assets/konnex/icons/logo.svg',
  carouselImages: [
    'assets/konnex/images/bg1.jpg',
    'assets/konnex/images/bg2.jpg',
    'assets/konnex/images/bg3.jpg',
  ]
};

